import * as React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PageHeader from "../components/PageHeader";
import PageLayout from "../components/PageLayout";
import Separator from "../components/Separator";
import facebookIcon from "../images/social/facebook-dark.svg";
import gitHubIcon from "../images/social/github-dark.svg";
import linkedInIcon from "../images/social/linkedin-dark.svg";
import slackIcon from "../images/social/slack-dark.svg";
import twitterIcon from "../images/social/twitter-dark.svg";
import { SocialLink } from "../types";

const socialLinks: SocialLink[] = [
  {
    url: "https://www.linkedin.com/in/matthewgoslett/",
    image: linkedInIcon,
    text: "linkedin.com/in/matthewgoslett",
  },
  {
    url: "https://github.com/matthewgoslett",
    image: gitHubIcon,
    text: "github.com/matthewgoslett",
  },
  {
    url: "https://www.facebook.com/matthew.goslett",
    image: facebookIcon,
    text: "facebook.com/matthew.goslett",
  },
  {
    url: "https://zatech.github.io/",
    image: slackIcon,
    text: "zatech/matthewgoslett",
  },
];

const ContactPage = () => {
  return (
    <PageLayout pageTitle="Contact Matthew Goslett">
      <Container className="mt-4">
        <PageHeader header="Contact" />
        <p className="mb-4">
          If you'd like to get in touch with me, feel free to ping me on social
          media, drop me an e-mail, or leave a message using the contact form
          below.
        </p>
        <div className="d-flex flex-column flex-md-row flex-wrap mb-4">
          {socialLinks.map((link, index) => {
            return (
              <a key={index} href={link.url} className="mr-4 mb-3">
                <img src={link.image} alt="" width="40" />
                {link.text && (
                  <span className="text-dark font-italic d-md-none ml-2">
                    {link.text}
                  </span>
                )}
              </a>
            );
          })}
        </div>
        <h3>Email me</h3>
        <Separator small />
        <Row>
          <Col md="6">
            <Form
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="contact"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <Form.Group controlId="formName">
                <Form.Label>Name *</Form.Label>
                <Form.Control type="name" name="name" required />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email *</Form.Label>
                <Form.Control type="email" name="email" required />
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Label>Message *</Form.Label>
                <Form.Control as="textarea" name="message" rows={5} required />
              </Form.Group>
              <Button variant="primary" size="lg" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </PageLayout>
  );
};

export default ContactPage;
